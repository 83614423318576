<template>
    <div class="popup">
        <span
            class="popup__close"
            @click="close"
        >
            <span class="icon-close"></span>
        </span>
        <slot/>
    </div>
</template>

<script>
import './popup.scss'

export default {
    name: 'Popup',
    props: {
        close: {
            type: Function,
            required: true
        }
    }
}
</script>